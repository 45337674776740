<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    horizontal?: boolean
    houseCount?: number
    images?: string[]
    sizeTextChangeResponsive?: boolean
    smallIcon?: boolean
  }>(),
  {
    horizontal: false,
    houseCount: 0,
    images: () => [],
    sizeTextChangeResponsive: true,
    smallIcon: false,
  },
)

const imagesGrid = 4
const moreOneHouse = computed(() => props.houseCount > 1)
const noImage = computed(() => props.images.length === 0)
const numberAdditionalHouses = computed(
  () => props.houseCount - props.images.length,
)
const numberMissingImages = computed(() => imagesGrid - props.images.length)

const imagesWithPlaceholder = computed(() => {
  if (noImage.value) {
    return [{ placholder: true }]
  }

  const array: { src?: string; placholder: boolean }[] = props.images.map(
    (img) => ({
      src: img,
      placholder: false,
    }),
  )

  if (numberMissingImages.value && moreOneHouse.value) {
    for (let i = 0; i < numberMissingImages.value; i++) {
      array.push({ placholder: true })
    }
  }

  return array
})
</script>

<template>
  <div :class="['grid', { 'grid-cols-2 gap-1': moreOneHouse }]">
    <div v-for="(image, index) in imagesWithPlaceholder" :key="index">
      <div
        v-if="image.placholder"
        :class="[
          'flex items-center justify-center bg-gray-100 aspect-square rounded',
        ]"
      >
        <BaseIcon
          :size="smallIcon ? 1 : 1.5"
          class="text-gray-500"
          name="addCircle"
        />
      </div>
      <div v-else class="relative">
        <BaseNuxtImg
          :src="image.src"
          alt="img"
          width="300"
          height="300"
          class="rounded"
        />
        <span
          v-if="index === 3 && numberAdditionalHouses"
          :class="[
            'flex items-center justify-center absolute top-0 left-0 w-full h-full bg-gray-700/50 text-white text-xs rounded',
            { 'tablet:text-6xl': sizeTextChangeResponsive },
          ]"
        >
          +{{ numberAdditionalHouses }}
        </span>
      </div>
    </div>
  </div>
</template>
